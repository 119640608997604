import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';

import { FormStateService } from '../../_services/form-state-service.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (window.scrollY < 360) {
      this.hideCheckPrices = true;
    } else {
      this.hideCheckPrices = false;
    }
  }

  @Input() hideCheckPricesOnScroll: boolean;
  @Input() menuIsOpen: boolean;
  @Input() renderCheckPrices: boolean;

  @Input() pageTitle: string;
  @Input() phoneNumber: string;

  @Output() clickedCheckPrices = new EventEmitter();
  @Output() clickedHeaderItem = new EventEmitter();
  @Output() clickedMenuItem = new EventEmitter();
  @Output() clickedPhone = new EventEmitter();
  @Output() menuToggled = new EventEmitter();

  public hideCheckPrices: boolean;

  constructor(
    private formStateService: FormStateService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.hideCheckPricesOnScroll = false;
    this.menuIsOpen = false;
    this.renderCheckPrices = false;

    this.pageTitle = '';
    this.phoneNumber = '';

    if (isPlatformBrowser(platformId)) {
      if (window.scrollY < 360) {
        this.hideCheckPrices = true;
      } else {
        this.hideCheckPrices = false;
      }
    }
  }

  public hideMenu(): void {
    this.menuToggled.emit(false);
  }

  public toggleMenu(): void {
    this.menuToggled.emit(!this.menuIsOpen);
  }

  public resetFormValidity(): void {
    this.formStateService.resetStepValidity();
  }
}
