<div
  class="floating fixed bottom-0 left-0 z-20 w-full max-xl:pb-2"
  [ngClass]="{
    'xl:bottom-[40px]': whatsAppCTAIsAbove,
    'z-50': menuIsOpen,
    hidden: !renderCheckPrices,
  }"
>
  <div
    class="container mx-auto flex justify-between px-4 max-xl:space-x-4 xl:relative"
  >
    <a
      class="btn btn-lg btn-secondary elevation-3 grow bg-primary-400 xl:hidden"
      [href]="'tel:' + phoneNumber"
      (click)="clickedOrderNow.emit()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-telephone-fill"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
        />
      </svg>

      Peça agora

      {{ phoneNumber }}
    </a>

    <a
      class="elevation-3 flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#25D366] xl:absolute xl:bottom-12 xl:right-4 xl:h-[56px] xl:w-[56px]"
      [href]="
        'https://api.whatsapp.com/send?phone=551141182971&text=' +
        whatsAppWelcome
      "
      target="_blank"
      (click)="clickedWhatsApp.emit()"
    >
      <img
        class="xl:h-[28px] xl:w-[28px]"
        ngSrc="../../../assets/images/icons/social-whatsapp-white.svg"
        width="24"
        height="24"
        alt=""
      />

      <span class="sr-only">WhatsApp</span>
    </a>
  </div>
</div>
