<div class="dialog-backdrop" [ngClass]="{ hidden: !show }">
  <button class="dialog-backdrop-close" type="button" (click)="handleClose()">
    <span class="sr-only">Fechar</span>
  </button>

  <div class="dialog">
    <div class="dialog-header">
      <h5 class="dialog-header-title">Troca complexa</h5>

      <button class="close" type="button" (click)="handleClose()">
        <span class="sr-only">Fechar</span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
          />
        </svg>
      </button>
    </div>

    <div class="dialog-body">
      <div class="dialog-body-content">
        <h4 class="dialog-body-title">
          A troca de bateria do seu {{ selectedVehicle.car_model }} não é
          simples
        </h4>

        <p>
          Seu veículo requer um processo especial para a instalação da bateria.
          Por isso, recomendamos que a troca seja feita em uma loja física.
        </p>

        <p>Deseja buscar uma revenda próxima?</p>
      </div>
    </div>

    <div class="dialog-footer">
      <button
        class="btn btn-md btn-ghost"
        type="button"
        (click)="handleSearchRetailer()"
      >
        Buscar revenda
      </button>

      <button
        class="btn btn-md btn-outline"
        type="button"
        (click)="handleClose()"
      >
        Fechar
      </button>
    </div>
  </div>
</div>
