<div
  class="fixed left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-primary-500/15"
  [ngClass]="{ hidden: !show }"
>
  <img
    ngSrc="../assets/images/animations/spinner.svg"
    width="64"
    height="64"
    alt="Carregando…"
  />

  <span class="sr-only">Carregando…</span>
</div>
