import { SwipeModule } from 'ng-swipe';

import {
  isPlatformBrowser,
  NgOptimizedImage,
  registerLocaleData,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ErrorHandler, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { BaseboardComponent } from './_components/baseboard/baseboard.component';
import { DialogBatteryUnavailableComponent } from './_components/dialog-battery-unavailable/dialog-battery-unavailable.component';
import { DialogCityInactiveComponent } from './_components/dialog-city-inactive/dialog-city-inactive.component';
import { DialogCityUnavailableComponent } from './_components/dialog-city-unavailable/dialog-city-unavailable.component';
import { DialogComplexExchangeComponent } from './_components/dialog-complex-exchange/dialog-complex-exchange.component';
import { DialogServiceUnavailableComponent } from './_components/dialog-service-unavailable/dialog-service-unavailable.component';
import { FloatingComponent } from './_components/floating/floating.component';
import { SpinningComponent } from './_components/spinning/spinning.component';
import { ToolbarComponent } from './_components/toolbar/toolbar.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CpfCnpjValidatorDirective } from './steps/form/validators/cpf-cnpj.validator.directive';

declare global {
  interface Window {
    _mfq: Array<any>;
  }
}

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    BaseboardComponent,
    CpfCnpjValidatorDirective,
    DialogBatteryUnavailableComponent,
    DialogCityInactiveComponent,
    DialogCityUnavailableComponent,
    DialogComplexExchangeComponent,
    DialogServiceUnavailableComponent,
    FloatingComponent,
    SpinningComponent,
    ToolbarComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({
      appId: 'serverApp',
    }),
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    NgOptimizedImage,
    SwipeModule,
  ],
  providers: [
    Meta,
    Title,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: Window,
      useValue: isPlatformBrowser(PLATFORM_ID) ? window : Window,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
